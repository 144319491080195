"use client";
import { useState } from "react";
import dynamic from "next/dynamic";
import { usePathname, useSelectedLayoutSegment } from "next/navigation";
import useScrollToTop from "@/app/hooks/useScrollToTop";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import styles from "../../../styles/Header/Header.module.scss";
const MenuHeader = dynamic(
	() => import("@/app/components/organisms/MenuHeader/MenuHeader")
);
const InteractiveLink = dynamic(
	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
);
const SearchButton = dynamic(
	() => import("@/app/components/atoms/SearchButton")
);
const HamburgerButton = dynamic(
	() => import("@/app/components/atoms/HamburgerButton")
);

type Props = {
	headerData?: any;
};

export default function Header({ headerData }: Props) {
	useScrollToTop();
	const { headerArray, headerItemsArray } = headerData;
	const [isOpenMenu, setOpenMenu] = useState(false);
	const activeSegment = useSelectedLayoutSegment();
	const isDiscover = activeSegment === "descubre";
	const isLive = activeSegment === "en-vivo";
	const pathName = usePathname();

	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: undefined,
		content_name: "Menu",
		content_type: "Button",
		contenidosBloque: undefined,
		countWidget: undefined,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: 1,
		seccion: "",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: "LinkText",
		tituloBloque: undefined,
		contentTitle: "",
	};

	const handleOpenMenu = (
		index: number,
		shouldOpen: boolean,
		data: any,
		item: string
	) => {
		setOpenMenu(shouldOpen);
		data.content_name = `Menu>Secciones>${item}`;
		handleDataLayerOnClick(data, index, pathName, true);
	};

	return (
		<header className={styles?.main_header}>
			<div className={styles?.inner_wrapper}>
				<div className={styles?.header_outer_flex}>
					<nav className={styles?.main_nav}>
						<ul className={styles?.list_flex_container}>
							<li
								className={styles?.logo_container}
								style={{
									borderBottom:
										activeSegment === null ? "2px solid white" : "none",
									height: activeSegment === null ? "30px" : "32px",
								}}
							>
								<InteractiveLink
									url="/"
									title="N+ Inicio"
									cardPosition={undefined}
									blockData={blockDataLayer}
									isLink={true}
								>
									<svg
										version="1.1"
										id="Layer_1"
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										viewBox="0 0 60 30"
									>
										<g>
											<path
												fill="#F1EFE3"
												d="M45.216,17.527V9.995h5.002v7.533h6.915v4.94h-6.915V30h-5.002v-7.533h-6.854v-4.94H45.216z"
											/>
											<polygon
												fill="#F1EFE3"
												points="2.867,29.983 21.289,29.983 22.774,24.029 7.602,11.03 		"
											/>
											<polygon
												fill="#F1EFE3"
												points="7.873,9.948 23.044,22.946 27.534,4.94 9.112,4.94 7.873,9.948 7.873,9.948 		"
											/>
											<polygon
												fill="#F1EFE3"
												points="23.867,23.651 31.278,30 38.757,0 29.763,0 		"
											/>
										</g>
									</svg>
								</InteractiveLink>
							</li>
							{headerArray?.slice(1)?.map((item: any, i: any) => {
								blockDataLayer.seccion = item?.link;
								return (
									<li
										key={item?.id}
										className={
											isDiscover && item?.link === "/descubre"
												? `${styles?.main_nav__item} ${styles?.activeBlue}`
												: isLive && item?.link === "/en-vivo"
												? `${styles?.main_nav__item} ${styles?.activeRed}`
												: `${styles?.main_nav__item}`
										}
									>
										<InteractiveLink
											url={item?.link}
											title={item?.value}
											cardPosition={undefined}
											blockData={blockDataLayer}
											isLink={true}
											className={`${styles["navText"]}`}
										>
											{item?.value}
										</InteractiveLink>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
				<div className={styles?.action_container}>
					<SearchButton />
					<HamburgerButton
						handleClick={() => {
							return handleOpenMenu(
								0,
								true,
								blockDataLayer,
								"ButtonHamburgerOpen"
							);
						}}
					/>
				</div>
			</div>
			<MenuHeader
				menu={headerItemsArray}
				isOpenMenu={isOpenMenu}
				setOpenMenu={handleOpenMenu}
			/>
		</header>
	);
}
